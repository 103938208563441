@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  font-family: Poppins, sans-serif;
  margin: 0;
  padding: 0;
  background: var(--bg);
  transition: all 3s ease;
  color: var(--black);
  scroll-behavior: smooth;
}